<template>
  <div class="speaking-sentence-container layout-border">
    <PageButton
      :bottomCornerButtonList="bottomCornerButtonList"
      :isLastStep="isLastStep"
      @clickNext="nextPage"
    ></PageButton>
    <div class="game-content">
      <SceneSwitchThumbnails
        :buttons="buttonList"
        :numBers="currentIndex"
        @changeTheNumbers="changeTheNumbers"
      >
      </SceneSwitchThumbnails>
      <div class="title-area" :class="{hasMultiLine: titleInfo[1].pinyin}">

        <div class="title" v-if="titleInfo[0].pinyin">
          <div class="title-pinyin pinyin font-pinyin-medium">
            {{ titleInfo[0].pinyin }}
          </div>
          <div class="title-hanzi font-hanzi-medium">
            {{ titleInfo[0].hanzi }}
          </div>
          <div class="title-pinyin pinyin font-pinyin-medium">
            {{ titleInfo[1].pinyin }}
          </div>
          <div class="title-hanzi font-hanzi-medium">
            {{ titleInfo[1].hanzi }}
          </div>
        </div>
        <div class="title" v-else>
          <div class="title-pinyin pinyin" :class="[titleLength == 'long' ? 'font-pinyin-small': 'font-pinyin-medium']">
            {{ titleInfo.pinyin }}
          </div>
          <div class="title-hanzi" :class="[titleLength == 'long' ? 'font-hanzi-small': 'font-hanzi-medium']">
            {{ titleInfo.hanzi }}
          </div>
        </div>
      </div>
      <div class="bgImg-area">
        <template v-for="(item, index) in gameList">
          <div class="img-box" :key="index" v-if="index + 1 === currentIndex">
            <transition name="el-fade-in">
              <img :src="item.bgImg" alt="" />
            </transition>
          </div>
        </template>
      </div>

      <div :class="'bubble-area-'+this.currentIndex">
        <template v-for="(item, index) in gameList">
          <img
            :key="index"
            @click.once="handleClickImg"
            :src="isShowBubble ? item.bubbleImg : questionBubbleImg"
            alt=""
            v-if="currentIndex === index + 1"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { startConfetti, playCorrectSound } from "@/utils/tools";
import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";
import SceneSwitchThumbnails from "@/components/Course/CoursePage/StorySwitcher/sceneSwitchThumbnails.vue";
export default {
  name: "SpeakingBubbleGame",
  props: {
    gameList: {
      type: Array,
      require: true,
      default: () => [],
    },
    buttonList: {
      type: Array,
      require: true,
      default: () => [],
    },
    titleInfo: {
      type: Object,
      default: () => {},
    },
    questionBubbleImg: {
      type: String,
      rquire: true,
    },
    titleLength: {
      type: String,
      require: false,
    },
  },
  components: {
    PageButton,
    SceneSwitchThumbnails,
  },
  watch: {
    clickMenuSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2005301,
          data: { value },
          text: "SpeakingBubbleGame点击Menu socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
    clickBubbleSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2005302,
          data: { value },
          text: "SpeakingBubbleGame点击Bubble socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
  },
  mounted() {
    this.$bus.$on("speakingBubbleClickMenu", ({ index }) => {
      this.changeTheNumbers(index, true);
    });
    this.$bus.$on("clickSpeakingBubble", () => {
      this.handleClickImg("socket", true);
    });
  },
  beforeDestroy() {
    this.$bus.$off("speakingBubbleClickMenu");
    this.$bus.$off("clickSpeakingBubble");
  },
  data() {
    return {
      currentIndex: 1,
      isLastStep: false,
      clickMenuSocketInfo: {},
      clickBubbleSocketInfo: {},
      isShowBubble: false,

      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "speak",
            startNumberRange: 0,
            endNumberRange: 100,
          },
        ],
      },
    };
  },
  methods: {
    changeTheNumbers(index, isFromSocket = false) {
      console.log(index);
      this.isShowBubble = false;
      this.currentIndex = index;
      if (!isFromSocket) {
        this.clickMenuSocketInfo = {
          index,
          value: Math.random(),
        };
      }
      if (this.currentIndex === this.gameList.length) {
        // this.isLastStep = true;
      } else {
        this.isLastStep = false;
      }
    },
    nextPage() {
      this.$bus.$emit("nextButton", true);
    },
    handleClickImg(socket, isFromSocket = false) {
      if (!isFromSocket) {
        this.clickBubbleSocketInfo = {
          value: Math.random(),
        };
      }
      this.isShowBubble = true;
      playCorrectSound(true, false);
      if (this.currentIndex === this.gameList.length) {
        this.isLastStep = true;
        playCorrectSound();
        startConfetti();
      } else {
        this.isLastStep = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.speaking-sentence-container {
  .game-content {
    width: 100%;
    height: 100%;
    position: relative;
    .bgImg-area {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      .img-box {
        cursor: pointer;
        width: 100%;
        height: 100%;
        img {
          border-radius: 58px;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    .title-area {
      z-index: 11;
      position: absolute;
      left: 0;
      top: 10%;
      width: 32%;
      height: 22%;
      background: #224e96;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-right-radius: 62px;
      border-bottom-right-radius: 62px;
      color: #fff;
      .title {
        .title-pinyin,
        .title-hanzi {
          text-align: center;
        }
      }

      &.hasMultiLine {
        height: auto;
        padding:10px;
      }
    }

    .bubble-area {
      //   background: #000;
      position: absolute;
      width: 50%;
      height: 50%;
      bottom: 20%;
      left: 5%;
      display: flex;
      cursor: pointer;
      img {
        height: 100%;
        // height: 100%;
        // object-fit: cover;
      }
    }
     .bubble-area-1 {
      //   background: #000;
      position: absolute;
      width: 30%;
      height: 30%;
      bottom: 15%;
      left: 18%;
      display: flex;
      cursor: pointer;
      img {
        height: 100%;
      }
    }
     .bubble-area-2 {
      //   background: #000;
      position: absolute;
      width: 30%;
      height: 30%;
      bottom: 36%;
      left: 18%;
      display: flex;
      cursor: pointer;
      img {
        height: 100%;
      }
    }
    .bubble-area-3 {
      //   background: #000;
      position: absolute;
      width: 30%;
      height: 30%;
      bottom: 3%;
      left: 26%;
      display: flex;
      cursor: pointer;
      img {
        height: 100%;
      }
    }
  }
}
</style>